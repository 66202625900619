//Write a function that will read url
export default function getURL() {
  let url: any = window.location.href
  url = url.split('/')
  url = url[url.length - 1]

  switch (url) {
    case 'agentapp':
      return 'Dashboard'
    case 'reports':
      return 'Reports'
    case 'credits':
      return 'Credits'
    case 'profile':
      return 'Profile'
    case 'reload-credits':
      return 'Reload Credits'
    case 'withdraw-commission':
      return 'Withdraw Commission'
    case 'credit-conversion':
      return 'Credit Conversion'
  }
}
