/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'
import getURL from '../../../../app/functions/GetURL'

const Footer = () => {
  const {config} = useLayout()
  const theme = localStorage.getItem('kt_theme_mode_value')
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='w-100 d-flex justify-content-evenly'>
        <Link
          className='d-flex flex-column align-items-center'
          to='/agentapp'
          style={{
            color:
              getURL() == 'Dashboard' && theme == 'dark'
                ? 'white'
                : getURL() == 'Dashboard' && theme == 'light'
                ? 'black'
                : 'grey',
            fontWeight: getURL() == 'Dashboard' ? 'bold' : 'normal',
          }}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen001.svg'
            className='svg-icon-muted svg-icon-2x'
          />
          Dashboard
        </Link>
        <Link
          className='d-flex flex-column align-items-center '
          to='/reports'
          style={{
            color:
              getURL() == 'Reports' && theme == 'dark'
                ? 'white'
                : getURL() == 'Reports' && theme == 'light'
                ? 'black'
                : 'grey',
            fontWeight: getURL() == 'Reports' ? 'bold' : 'normal',
          }}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen005.svg'
            className='svg-icon-muted svg-icon-2x'
          />
          Reports
        </Link>
        <Link
          className='d-flex flex-column align-items-center '
          to='/credits'
          style={{
            color:
              getURL() == 'Credits' && theme == 'dark'
                ? 'white'
                : getURL() == 'Credits' && theme == 'light'
                ? 'black'
                : 'grey',
            fontWeight: getURL() == 'Credits' ? 'bold' : 'normal',
          }}
        >
          <KTSVG
            path='/media/icons/duotune/finance/fin010.svg'
            className='svg-icon-muted svg-icon-2x'
          />
          Credits
        </Link>
        <Link
          className='d-flex flex-column align-items-center '
          to='/profile'
          style={{
            color:
              getURL() == 'Profile' && theme == 'dark'
                ? 'white'
                : getURL() == 'Profile' && theme == 'light'
                ? 'black'
                : 'grey',
            fontWeight: getURL() == 'Profile' ? 'bold' : 'normal',
          }}
        >
          <KTSVG
            path='/media/icons/duotune/communication/com006.svg'
            className='svg-icon-muted svg-icon-2x'
          />
          Profile
        </Link>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
