import axios from 'axios'
import {useFormik} from 'formik'
import {useState} from 'react'
import * as Yup from 'yup'
import {getCurrentDateInput} from '../../functions/GetCurrentDateInput'
import clsx from 'clsx'
import getFirstDayOfCurrentMonth from '../../functions/GetFirstDayOfMonth'

export default function Dashboard_Filter(props: any) {
  const REACT_APP_AGENT_API = process.env.REACT_APP_AGENT_API
  let setSummaryData = props.setSummaryData
  const username = localStorage.getItem('username')
  const session = localStorage.getItem('session')

  const [loading, setLoading] = useState(false)

  const initialValues = {
    dateTimeFrom: getFirstDayOfCurrentMonth(),
    dateTimeTo: getCurrentDateInput(),
  }

  const filterSchema = Yup.object().shape({
    search: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    dateTimeFrom: Yup.date(),
    dateTimeTo: Yup.date().min(Yup.ref('dateTimeFrom'), "End date can't be before start date"),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: filterSchema,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)

      let dateTimeFrom = values.dateTimeFrom.trim()
      let dateTimeTo = values.dateTimeTo.trim()

      axios
        .post(
          `${REACT_APP_AGENT_API}/account/summary`,
          {
            request: {
              username: username,
              fromDate: dateTimeFrom,
              toDate: dateTimeTo,
              session: session,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function (response) {
          if (response.data.code == 200) {
            let result = response.data.result
            console.log(result)
            setSummaryData(result)
          } else {
            alert(response.data.message)
          }
          setLoading(false)
        })
        .catch(function (error) {
          alert(error)
          setLoading(false)
        })
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} className='m-3'>
      <div className='row d-flex justify-content-around'>
        <div className='col-4 p-1 m-0'>
          {/* begin::Form group DateTime From */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>From</label>
            <input
              placeholder='Enter your start time'
              type='date'
              autoComplete='off'
              {...formik.getFieldProps('dateTimeFrom')}
              className={clsx(
                'form-control bg-transparent fs-8',
                {
                  'is-invalid': formik.touched.dateTimeFrom && formik.errors.dateTimeFrom,
                },
                {
                  'is-valid': formik.touched.dateTimeFrom && !formik.errors.dateTimeFrom,
                }
              )}
            />
            {formik.touched.dateTimeFrom && formik.errors.dateTimeFrom && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dateTimeFrom}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
        </div>
        <div className='col-4 p-1 m-0'>
          {/* begin::Form group DateTime To */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>To</label>
            <input
              placeholder='Enter your end time'
              type='date'
              autoComplete='off'
              {...formik.getFieldProps('dateTimeTo')}
              className={clsx(
                'form-control bg-transparent fs-8',
                {
                  'is-invalid': formik.touched.dateTimeTo && formik.errors.dateTimeTo,
                },
                {
                  'is-valid': formik.touched.dateTimeTo && !formik.errors.dateTimeTo,
                }
              )}
            />
            {formik.touched.dateTimeTo && formik.errors.dateTimeTo && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.dateTimeTo}</span>
                </div>
              </div>
            )}
          </div>{' '}
          {/* end::Form group */}
        </div>
        <div className='col-4 p-1 m-0'>
          {/* begin::Form group */}
          <div className='text-center'>
            <label className='form-label fw-bolder text-dark fs-6 invisible'>To</label>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5 fs-8'
              style={{height: '35px'}}
              disabled={formik.isSubmitting || !formik.isValid || loading}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Form group */}
        </div>
      </div>
    </form>
  )
}
