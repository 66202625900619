/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import {useState} from 'react'
import getURL from '../../../../app/functions/GetURL'

export function HeaderWrapper() {
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  // const [url, setUrl] = useState(getURL())

  return (
    <div id='kt_app_header' className='app-header'>
      {/* Hamburger Menu Button :: START */}
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1 w-100',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <div className='d-flex justify-content-between w-100 fw-bold'>
            {getURL() !== 'Reload Credits' &&
            getURL() !== 'Withdraw Commission' &&
            getURL() !== 'Credit Conversion' ? (
              <div className='d-flex align-items-center d-lg-none ms-n2' title='Show sidebar menu'>
                <div
                  className='btn btn-icon btn-active-color-primary w-35px h-35px'
                  id='kt_app_sidebar_mobile_toggle'
                >
                  <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
                </div>
              </div>
            ) : (
              <div className='d-flex align-items-center d-lg-none ms-n2' title='Show sidebar menu'>
                <div
                  onClick={() => window.history.back()}
                  className='btn btn-icon btn-active-color-primary w-35px h-35px'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className=' svg-icon-1' />
                </div>
              </div>
            )}
            <div className='d-flex align-items-center d-lg-none ms-n2 me-10'>{getURL()}</div>
            <div></div>
          </div>
        )}

        {/* Hamburger Menu Button :: END */}

        {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                <Header />
              </div>
            )}
          <Navbar />
        </div>
      </div>
    </div>
  )
}
