import {FC, useEffect, useState} from 'react'
import '../../index.scss'
import axios from 'axios'
import Dashboard_Filter from './Dashboard_Filter'
import {getCurrentDateInput} from '../../functions/GetCurrentDateInput'
import getFirstDayOfCurrentMonth from '../../functions/GetFirstDayOfMonth'
import FormatNumber from '../../functions/FormatNumber'

const DashboardPage: FC = () => {
  const REACT_APP_AGENT_API = process.env.REACT_APP_AGENT_API

  // Get the full URL
  var currentURL = window.location.href

  // Get the query string portion of the URL
  var queryString = window.location.search

  // Create a URLSearchParams object to parse the query string
  var searchParams = new URLSearchParams(queryString)

  // Get the value of a specific parameter
  var session = searchParams.get('session')
  var username = searchParams.get('username')

  if (session !== null) {
    localStorage.setItem('session', session)
  }

  if (username !== null) {
    localStorage.setItem('username', username)
  }

  const [summaryData, setSummaryData] = useState({
    totalDepositAmt: 0,
    totalWithdrawalAmt: 0,
    totalTransaction: 0,
    availableCredit: 0,
  })

  function getSummaryData(REACT_APP_AGENT_API: any) {
    let result
    axios
      .post(
        `${REACT_APP_AGENT_API}/account/summary`,
        {
          request: {
            username: localStorage.getItem('username'),
            fromDate: getFirstDayOfCurrentMonth(),
            toDate: getCurrentDateInput(),
            session: localStorage.getItem('session'),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        if (response.data.code == 200) {
          let result = response.data.result
          console.log(result)
          setSummaryData(result)
        } else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {
        alert(error)
      })
  }

  useEffect(() => {
    getSummaryData(REACT_APP_AGENT_API)
  }, [])

  return (
    <div className='d-flex flex-column'>
      {/* <PageTitle breadcrumbs={[]}>{'Dashboard'}</PageTitle> */}
      <Dashboard_Filter setSummaryData={setSummaryData} />
      <div className='row'>
        <div className='col'>
          <div className='card card-custom card-flush'>
            <div className='card-header py-3'>
              <h4 className='card-title fs-6'>Total Cash-Out</h4>
            </div>
            <div className='card-body py-3'>
              <h4 className='card-title fs-1'>{FormatNumber(summaryData.totalDepositAmt)}</h4>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='card card-custom card-flush'>
            <div className='card-header py-3'>
              <h4 className='card-title fs-6'>Total Cash-In</h4>
            </div>
            <div className='card-body py-3'>
              <h4 className='card-title fs-1'>{FormatNumber(summaryData.totalWithdrawalAmt)}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col'>
          <div className='card card-custom card-flush'>
            <div className='card-header py-3'>
              <h4 className='card-title fs-6'>Total Transactions</h4>
            </div>
            <div className='card-body py-3'>
              <h4 className='card-title fs-1'>{summaryData.totalTransaction}</h4>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='card card-custom card-flush'>
            <div className='card-header py-3'>
              <h4 className='card-title fs-6'>Available Credits</h4>
            </div>
            <div className='card-body py-3'>
              <h4 className='card-title fs-1'>{FormatNumber(summaryData.availableCredit)}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
